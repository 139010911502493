export const ComplaintStatus = {
    RECEIVED: {
        code: "RECEIVED",        
        name: "Recibida",
        filter: "Recibida"
    },
    COMPETENT: {
        code: "COMPETENT",        
        name: "Competente",
        filter: "Competente"        
    },
    REJECTED: {
        code: "REJECTED",
        name: "Archivada",
        filter: "Archivada"        
    },
    ACCEPTED: {
        code: "ACCEPTED",
        name: "Aceptada",
        filter: "Aceptada"        
    },
    ASSIGNED: {
        code: "ASSIGNED",
        name: "Unidad asignada",
        filter: "Unidad asignada"
    },
    ANALYZING: {
        code: "ANALYZING",
        name: "En análisis",
        filter: "En análisis"        
    },
    SENDED_TO_DDEA: {
        code: "SENDED_TO_DDEA",
        name: "Enviado a la dirección de delitos económicos para análisis",
        shortName: "Dirección de delitos económicos y anticorrupción",
        filter: "Enviada a la DDEA"
    },
    JUDGED_BY_DDEA: {
        code: "JUDGED_BY_DDEA",
        name: "Dictamen recibido de la dirección de delitos económicos",
        shortName: "Dirección de delitos económicos y anticorrupción",
        filter: "Dictamen recibido de la DDEA"
    },
    TRANSFERRED: {
        code: "TRANSFERRED",
        name: "Transferida",
        filter: "Transferida"        
    }
};