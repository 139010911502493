import React, { useEffect, useState } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import styles from "./prosecutorUnit.module.css";
import axios from "axios";
import toast from "react-hot-toast";
import ProsecutorUnitHistory from "./History/prosecutorUnitHistory";
import ProsecutorUnitEdit from "./Edit/prosecutorUnitEdit";
import ProsecutorUnitEditProsecutor from "./Edit/prosecutorUnitAddProsecutor";
import ProsecutorUnitCreate from "./Edit/prosecutorUnitCreate";
import { FiTrash } from "react-icons/fi";
import { MdGroups, MdOutlinePersonAddAlt1 } from "react-icons/md";


const ProsecutorUnit = () => {

  const [modalHistory, setModalHistory] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [divisionId, setDivisionId] = useState(null);
  const [prosecutorEdit, setProsecutorEdit] = useState(false);
  const { keycloak } = useKeycloak();


  const UpdateListDivisions = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/divisions`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${keycloak.token}`,
        },
      }
    );

    setDivisions(data);
  };

  useEffect(() => {
    UpdateListDivisions();
    setIsLoading(false);
  }, [prosecutorEdit]);





  const handleDelete = (divisionId) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/divisions/${divisionId}`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
      .then(UpdateListDivisions(), toast("Se elimino la unidad correctamente"));
  };

  const handleEdit = (divisionId) => {
    setModalEdit(true);
    setDivisionId(divisionId);
  };

  const handleEditProsecutor = (divisionId) => {
    setProsecutorEdit(true);
    setDivisionId(divisionId);
  }

  const handleCloseEdit = () => {
    setModalEdit(false);
  }

  const handleCloseEditProsecutor = () => {
    setProsecutorEdit(false);
  }

  const handleCloseModalCreate = () => {
    setModalCreate(false);
  }

  return (
    <>
      <div className="container_header">
        <h4>Unidad Fiscal</h4>
      </div>
      {modalHistory ? (
        <>
          <ProsecutorUnitHistory setModalHistory={setModalHistory} />
        </>
      ) : (
        <></>
      )}
      {!modalHistory && (
        <>
        <ProsecutorUnitEdit
            divisionId={divisionId}
            setModalEdit={handleCloseEdit}
            show={modalEdit}
          />
        <ProsecutorUnitEditProsecutor 
        divisionId={divisionId} 
        setModalEdit={handleCloseEditProsecutor} 
        show={prosecutorEdit}/>
        <ProsecutorUnitCreate
        setModalCreate={handleCloseModalCreate}
        show={modalCreate}
        UpdateListDivisions={UpdateListDivisions}
        />
          <Form>
           <div className="container_header my-2" style={{display:'flex', justifyContent:'end'}}>
                <Button
                  bsPrefix={styles.newPrimary}
                  onClick={() => {
                    setModalCreate(true);
                  }}
                >
                  Agregar Unidad Fiscal
                </Button>
                <Button
                  className="mx-5"
                  onClick={() => {
                    setModalHistory(true);
                  }}
                  bsPrefix={styles.newPrimary}
                >
                  Historico
                </Button>
              </div>
            <Table className={styles.table}>
              <thead>
                <th>División</th>
                <th>Fiscal Asignado</th>
                <th>Acciones</th>
              </thead>
              <tbody>
                {divisions.map((division) => (
                  <tr>
                    <td>{division.name}</td>
                    <td>
                      {division.prosecutor && (
                        <>
                          {division.prosecutor.firstName}{" "}
                          {division.prosecutor.lastName}
                        </>
                      )}
                    </td>
                    <td>
                    <MdOutlinePersonAddAlt1 
                      onClick={() => {
                        handleEditProsecutor(division.id);
                      }}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    />

                      <MdGroups
                        title="Editar"
                        onClick={() => {
                          handleEdit(division.id);
                        }}
                        style={{ cursor: "pointer", marginRight: "5px", marginLeft: "5px" }}
                      />
                      <FiTrash
                        title="Eliminar"
                        onClick={() => {
                          handleDelete(division.id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Form>
        </>
      )}
    </>
  );
};

export default ProsecutorUnit;
