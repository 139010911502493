import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import styles from "../prosecutorUnit.module.css";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { useFetch } from "../../../../components/hooks/get";
const ProsecutorUnitCreate = ({
  show,
  setModalCreate,
  UpdateListDivisions,
}) => {
  const [unitName, setUnitName] = useState("");
  const [selectedProsecutor, setSelectedProsecutor] = useState("");
  const { keycloak } = useKeycloak();
  const { todo: prosecutors, isFetchingProsecutors } = useFetch(
    `${process.env.REACT_APP_API_URL}/prosecutors`,
    keycloak.token
  );

  const handleCreateDivision = async () => {
    const reqBody = {
      name: unitName,
      prosecutor_id: selectedProsecutor ? parseInt(selectedProsecutor) : null,
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}/divisions`, reqBody, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
      .catch((error) =>
        toast("Ocurrio un erro al intentar crear una nueva Unidad")
      );
    UpdateListDivisions();
  };
  const handleUnitNameChange = (e) => {
    setUnitName(e.target.value);
  };

  const handleProsecutorSelect = (e) => {
    setSelectedProsecutor(e.target.value);
  };

  return (
    <>
      <Toaster />
      <Modal show={show} onHide={setModalCreate}>
        <Modal.Header>
          <Modal.Title>
            <h5>Nueva Unidad Fiscal</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formUnitName">
              <Form.Label className={styles.label}>
                Nombre de la Unidad
              </Form.Label>
              <Form.Control
                type="text"
                className={styles.input}
                placeholder="Ingrese el nombre de la unidad"
                value={unitName}
                onChange={handleUnitNameChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formProsecutorSelect">
              <Form.Label className={styles.label}>Fiscal Asignado</Form.Label>
              {isFetchingProsecutors ? (
                <p>Cargando fiscales...</p>
              ) : (
                <Form.Select
                  value={selectedProsecutor}
                  onChange={handleProsecutorSelect}
                  className={styles.input}
                >
                  <option value="">Seleccionar Fiscal</option>
                  {prosecutors && prosecutors.length > 0 ? (
                    prosecutors.map((prosecutor) => {
                      return (
                        <option key={prosecutor.id} value={prosecutor.id}>
                          {`${prosecutor?.firstName ?? ""} ${
                            prosecutor?.lastName ?? ""
                          }`}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">Todos los fiscales estan asignados</option>
                  )}
                </Form.Select>
              )}
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsPrefix={styles.newPrimary}
            style={{ marginLeft: "2px" }}
            onClick={handleCreateDivision}
          >
            Agregar
          </Button>
          <Button
            bsPrefix={styles.newPrimary}
            style={{ marginLeft: "2px" }}
            onClick={setModalCreate}
          >
            Volver
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProsecutorUnitCreate;
