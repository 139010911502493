import React, { useEffect, useState } from "react";
import { Row, Spinner, Col, Table, Badge, Button } from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import TimeFilter from "../../TimeFilter";
import axios from "axios";
import styles from "./ResolvedCauses.module.css";
import { Complexity } from "../../../../constants/Complexity";
import { CauseStatus } from "../../../../constants/CauseStatus";
import CustomPagination from "../../../../components/pagination/CustomPagination";

const ResolvedCauses = ({ rangeType, setRangeType, year, setYear, month, setMonth, startDate, setStartDate, endDate, setEndDate }) => {

    const navigate = useNavigate();    

    const [ loading, setLoading ] = useState(false);
    const [ loadingExport, setLoadingExport ] = useState(false);
    const [ causeResolutions, setCauseResolutions ] = useState(undefined);    
    const [ details, setDetails ] = useState([]);
    const [ page, setPage ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ totalPages, setTotalPages ] = useState(10);
    const [ totalElements, setTotalElements ] = useState(0);

    const { keycloak } = useKeycloak();

    const optionsResolutionCauses = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: 'Causas judicializadas por unidad fiscal'
          },
        },
    };     

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    };

    const prepareHeadersBlob = () => {
        return {
            headers: {
                "Accept": "application/octet-stream",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    };     

    const mapResolutions = ( data ) => {

        let datasets = [], labels = [];
        let opportunityCriteria = [], onConditionalSuspension = [], abbreviateProcess = [], conciliated = [], imputed = [];

        for(let current of data){
            labels.push(`${current.prosecutor.firstName} ${current.prosecutor.lastName}`);
            opportunityCriteria.push(current.opportunityCriteria);
            onConditionalSuspension.push(current.onConditionalSuspension);
            abbreviateProcess.push(current.abbreviateProcess);
            conciliated.push(current.conciliated);
            imputed.push(current.imputed);
        }
        
        datasets.push({
            label: "Criterio de oportunidad",
            data: opportunityCriteria,
            backgroundColor: '#4CB899'
        });

        datasets.push({
            label: "En suspensión condicional",
            data: onConditionalSuspension,
            backgroundColor: '#91B5CD'
        });

        datasets.push({
            label: "Proceso abreviado",
            data: abbreviateProcess,
            backgroundColor: '#33ACFF'
        });

        datasets.push({
            label: "Conciliada",
            data: conciliated,
            backgroundColor: '#9AD0F5'
        });

        datasets.push({
            label: "Imputada",
            data: imputed,
            backgroundColor: '#EFEF65'
        });        

        return { labels, datasets };
    }    

    const lastDayOfMonth = (month, year) => {
        let fecha = new Date(year, month, 1);
        fecha.setDate(fecha.getDate() - 1);
        return fecha.getDate();
    }    

    const buildParams = () => {
        let params = "?";
        switch(rangeType){
            case "year":
                params += "start_date=" + year + "-01-01&";
                params += "end_date=" + year + "-12-31";
                break;
            case "month":
                params += "start_date=" + year + "-" + month + "-01&";
                params += "end_date=" + year + "-" + month + "-" + lastDayOfMonth(month, year);
                break;
            case "custom":
                if(startDate != undefined && endDate != undefined){
                    params += "start_date=" + startDate + "&";
                    params += "end_date=" + endDate;
                }
                break;
            default:
                break;
        }
        return params;
    }

    const buildDetailsParams = () => {
        let params = buildParams();
        params += "&page=" + page;
        params += "&page_size=" + pageSize;
        params += "&type=CLOSED";
        return params;
    }

    const findDashboardCauseResolution = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "/deputy/prosecutors/dashboard/cause/resolutions" + buildParams(), prepareHeaders())
        .then(res => {
            setCauseResolutions(res.data.status == 204 ? undefined : mapResolutions(res.data.prosecutorCauses));
            setLoading(false);
        })
        .catch(error => {
            setCauseResolutions(undefined);
            setLoading(false);
        });
    }

    const findDetails = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "/deputy/prosecutors/dashboard/details" + buildDetailsParams(), prepareHeaders())
        .then(res => {
            setDetails(res.data.status == 204 ? [] : res.data.content);
            setTotalPages(res.status == 206 ? res.data.totalPages : 10);
            setTotalElements(res.status == 206 ? res.data.totalElements : 0);
            setLoading(false);
        })
        .catch(error => {
            setDetails([]);
            setTotalElements(0);
            setLoading(false);
        })
    }

    const exportCauses = (e) => {
        e.preventDefault();
        setLoadingExport(true);
        axios({
            url: process.env.REACT_APP_API_URL + "/deputy/prosecutors/dashboard/export" + buildDetailsParams(),
            method: 'GET',
            responseType: 'blob',
            ...prepareHeadersBlob()
        })
        .then((response) => {
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'reporte_causas.xlsx';
            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) { 
                  filename = matches[1].replace(/['"]/g, '');
                }
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
    
            // Limpieza
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            setLoadingExport(false);            
        })
        .catch((error) => {
            console.error('Hubo un error al descargar el archivo: ', error);
            setLoadingExport(true);            
        });        
    }       

    useEffect(() => { findDashboardCauseResolution(); findDetails(); }, [startDate, endDate, rangeType, month, year]);
    
    return (
        <>
            {
                loading ? (
                    <Row>
                        <Spinner animation="grow" role="status" style={{ marginLeft: "auto", marginRight: "auto"}}>
                            <span className="visually-hidden">Cargando...</span>
                        </Spinner>                                            
                    </Row>
                )
                : 
                (
                    <>
                        {
                            causeResolutions ? (
                                <>
                                    <TimeFilter 
                                        rangeType={rangeType} 
                                        setRangeType={setRangeType}
                                        year={year}
                                        setYear={setYear}
                                        month={month}
                                        setMonth={setMonth}
                                        startDate={startDate}
                                        setStartDate={setStartDate}
                                        endDate={endDate}
                                        setEndDate={setEndDate}
                                    />                                  
                                    <Row>
                                        <Col md={12}>
                                            <Bar options={optionsResolutionCauses} data={causeResolutions} />
                                        </Col>
                                    </Row>
                                </>
                            ) 
                            : <></>
                        }
                        {
                            (details && details.length > 0) ? (
                                <>
                                    <Row style={{ marginTop: "1.5rem" }}>
                                        <Col md={6}>
                                            <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={(page) => { setPage(page); }} totalPages={totalPages}/>
                                        </Col>                                        
                                        <Col md={6} style={{ textAlign: "right", marginTop: "auto" }}>                                            
                                            {
                                                loadingExport ? (
                                                    <Spinner animation="grow" className="mb-2"/>
                                                )
                                                : (
                                                    <>
                                                        <a alt="Descargar reporte" href="#" onClick={exportCauses}>Descargar</a>&nbsp;&nbsp;
                                                    </>
                                                )
                                            }                                            
                                            <span>{totalElements} {totalElements > 1 ? "causas" : "causa"}</span>
                                        </Col>
                                    </Row>                                
                                    <Row style={{ marginTop: "1.5rem" }}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <th>N° causa</th>
                                                <th>N° denuncia</th>
                                                <th>Carátula</th>
                                                <th>Creación</th>
                                                <th>Estado</th>
                                                <th>Complejidad</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                {
                                                    details.map((cause) => (
                                                        <tr>
                                                            <td>{cause.id}</td>
                                                            <td>{cause.complaint.id}</td>
                                                            <td>{cause.cover}</td>
                                                            <td>{cause.createdAt}</td>
                                                            <td>{CauseStatus[cause.status].name}</td>
                                                            <td>
                                                                <Badge pill className={styles.complexityLevel} bg={cause.causeSeverityLevel === "LOW" ? "success" : (cause.causeSeverityLevel === "MEDIUM" ? "warning" : "danger")}>{`${Complexity[cause.causeSeverityLevel || "NONE"].name}`}</Badge>
                                                            </td>
                                                            <td>
                                                                <div style={{ display: "flex"}}>
                                                                    <Button bsPrefix={styles.next} onClick={(e) => { navigate(`/causas/${cause.id}`) }}>VER</Button>                                                                  
                                                                </div>
                                                            </td>
                                                        </tr> 
                                                    ))
                                                }
                                            </tbody>
                                        </Table>    
                                    </Row>
                                </>
                            )
                            :
                            <></>
                        }
                    </>
                )
            }
        </>
    );

}

export default ResolvedCauses;