import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch } from 'react-icons/ai';
import {Form, Button, Col, Row, DropdownButton, Dropdown, Badge, Table, InputGroup } from "react-bootstrap";
import { RiSortAsc, RiSortDesc, RiCloseFill, RiTableFill, RiArticleFill } from "react-icons/ri";
import { AiOutlineClear } from "react-icons/ai";
import { FaFileCirclePlus } from "react-icons/fa6";
import styles from "./DenunciasConsulta.module.css";
import Denuncia from "./Denuncia/Denuncia";
import CustomPagination from "../../components/pagination/CustomPagination";
import { useKeycloak } from "@react-keycloak/web";
import { checkRoles } from "../../Keycloak";
import EconomicCrimesUnitComplaint from "./EconomicCrimesUnitComplaint/EconomicCrimesUnitComplaint";
import { useSearchParams } from "react-router-dom";
import { ComplaintStatus } from "../../constants/ComplaintStatus";
import { ComplaintType } from "../../constants/ComplaintType";
import { useNavigate } from "react-router-dom";
import CrimesModal from "../../components/modals/CrimesModal";

const DenunciasConsulta = () => {

    let [searchParams, setSearchParams] = useSearchParams();
    const [ divisions, setDivisions ] = useState([]);    
    const [ injuredParties, setInjuredParties ] = useState([]);        

    const navigate = useNavigate();    

    const [ view, setView ] = useState("C");

    const { keycloak } = useKeycloak();
    const [complaints, setComplaints] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(2);
    const [totalPages, setTotalPages] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [id, setId] = useState(searchParams.get("id"));
    const [lteDate, setLteDate] = useState(undefined);
    const [gteDate, setGteDate] = useState(undefined);
    const [lteStatementDate, setLteStatementDate] = useState(undefined);
    const [gteStatementDate, setGteStatementDate] = useState(undefined);
    const [status, setStatus] = useState(undefined);
    const [complaintType, setComplaintType] = useState(undefined);
    const [sorting, setSorting] = useState([]);
    const [showCrimeModal, setShowCrimeModal] = useState(false);
    const [selectedCrimes, setSelectedCrimes] = useState([]);

    const [ division, setDivision ] = useState({});
    const [ injuredParty, setInjuredParty ] = useState(undefined);
    const [ name, setName ] = useState(undefined);
    const [ witnessName, setWitnessName ] = useState(undefined);
    const [ reference, setReference ] = useState(undefined);
    const [ documentNumber, setDocumentNumber ] = useState(undefined);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${keycloak.token}`,
                "channel": "1"
            }
        };
    }

    const reset = (e) => {
        e.preventDefault();
        setPage(1);
        setId("");
        setLteDate("");
        setGteDate("");
        setLteStatementDate("");
        setGteStatementDate("");
        setStatus("");
        setComplaintType("");
        setSorting([]);
        setDivision({});
        setInjuredParty(null);
        setName("");
        setWitnessName("");
        setReference("");
        setDocumentNumber("");
    }

    const handleCloseCrimeModal = (e) => {

        setShowCrimeModal(false);
    }

    const findDivisions = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/divisions`, prepareHeaders())        
        .then(res => { setDivisions(res.status != 200 ? [] : res.data); })
        .catch(err => { console.log(err); });
    }    

    const findInjuredParties = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/injuredParties`, prepareHeaders())
        .then(res => setInjuredParties(res.status == 204 ? [] : res.data))
        .catch(err => {
            setInjuredParties([]);
        });
    }        

    const findComplaints = () => {
        setLoading(true);
        const resource = checkRoles(keycloak) === "ddea" ? "/economic/crimes/complaints" : "/complaints";
        //const URL = "http://localhost:8080/udea.deputy-prosecutor.reception/api" + `${resource}?page=${page}&pageSize=${pageSize}` + buildParams();
        const URL = process.env.REACT_APP_API_URL + `${resource}?page=${page}&pageSize=${pageSize}` + buildParams();
        axios.get(URL, prepareHeaders())
        .then(res => {
            setComplaints(res.status == 206 ? res.data.content : []);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
            setLoading(false);
        })
        .catch(err => {
            setComplaints([]);
            setLoading(false);
        });
    }

    const buildParams = () => {
        let query = "";
        if(id){ query += `&id=${id}` }
        if(lteDate){ query += `&lteCreationDate=${lteDate}` }
        if(gteDate){ query += `&gteCreationDate=${gteDate}` }
        if(lteStatementDate){ query += `&lteStatementOfFactDate=${lteStatementDate}` }
        if(gteStatementDate){ query += `&gteStatementOfFactDate=${gteStatementDate}` }
        if(gteDate){ query += `&gteCreationDate=${gteDate}` }
        if(status && status != "TODOS"){ query += `&status=${status}` }
        if(complaintType && complaintType != "TODOS"){ query += `&complaintType=${complaintType}` }
        if(division.id && division.name != "TODOS"){ query += `&divisionId=${division.id}`; }
        if(name && name != ""){ query += `&name=${name}`; }
        if(witnessName && witnessName != ""){ query += `&witnessName=${witnessName}`; }
        if(selectedCrimes.length>0){
            selectedCrimes.map(crime => query+=`&crimes=${crime.id}`);
        
        }
        if(documentNumber && documentNumber != ""){ query += `&documentNumber=${documentNumber}`; }
        if(injuredParty && injuredParty != "TODOS"){ query += `&institution=${injuredParty}`; }
        if(reference){ query += `&referenceId=${reference}`; }
        if(sorting && sorting.length > 0){ sorting.map((sort) => { query += `&sortingBy=${sort.key}:${sort.direction}` }) }
        return query;
    }

    const sortingBy = (field, label) => {
        if(sorting.filter((element) => element.key === field).length > 0){
            setSorting(sorting.filter((element) => element.key !== field));
        }
        else {
            setSorting([...sorting, { key: field, label, direction: "ASC" }]);
        }
    }

    const changeDirection = (sort) => {
        let elements = [];
        for(let current of sorting){
            if(current.key === sort.key){
                current.direction = sort.direction === "ASC" ? "DESC" : "ASC";
            }
            elements.push(current);
        }
        setSorting(elements);
    }

    useEffect(() => findComplaints(), [page, sorting, pageSize]);
    useEffect(() => { findComplaints(); findDivisions(); findInjuredParties(); }, []);
    useEffect(() => setPage(1), [id, lteDate, gteDate, status]);
    
    return (
        <>
            <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>
            <Col md={3}>
                    <div className={styles.sideBar}>
                        <Form>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingComplaintNumber"
                                            type="number"
                                            placeholder="ID de la denuncia"
                                            value={id}
                                            className={styles.inputFloating}
                                            onChange={(e) => { setId(e.target.value); }}
                                        />
                                        <label htmlFor="floatingComplaintNumber" className={styles.label}>Número de denuncia</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Select id="floatingFiscal" className={styles.inputFloating} value={JSON.stringify(division)} onChange={(e) => { setDivision(JSON.parse(e.target.value))}}>
                                            <option value={JSON.stringify({firstName: "TODOS"})} selected>TODOS</option>
                                            {
                                                divisions.map((division) => (
                                                    <option value={JSON.stringify(division)}>{`${division.name} - ${division.prosecutor?.firstName} ${division.prosecutor?.lastName}`}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <label htmlFor="floatingFiscal" className={styles.label}>Unidad fiscal</label>
                                    </Form.Floating>
                                </Form.Group>                                
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Select id="floatingInstitution" className={styles.inputFloating} value={injuredParty} onChange={(e) => { setInjuredParty(e.target.value); }}> 
                                            <option value={"TODOS"} selected>TODOS</option>
                                            {
                                                injuredParties.map((injuredParty) => (
                                                    <option value={injuredParty.name}>{`${injuredParty.name}`}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <label htmlFor="floatingInstitution" className={styles.label}>Institución afectada</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingName"
                                            type="text"
                                            placeholder="Nombre del denunciante / denunciado"
                                            className={styles.inputFloating}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <label htmlFor="floatingName" className={styles.label}>Nombre del denunciante / denunciado</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingWitnessName"
                                            type="text"
                                            placeholder="Nombre del testigo"
                                            className={styles.inputFloating}
                                            value={name}
                                            onChange={(e) => setWitnessName(e.target.value)}
                                        />
                                        <label htmlFor="floatingWitnessName" className={styles.label}>Nombre del testigo</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingDocumentNumber"
                                            type="text"
                                            placeholder="Número de documento"
                                            className={styles.inputFloating}
                                            value={documentNumber}
                                            onChange={(e) => setDocumentNumber(e.target.value)}
                                        />
                                        <label htmlFor="floatingDocumentNumber" className={styles.label}>Número de documento</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingReference"
                                            type="text"
                                            placeholder="Número de referencia"
                                            className={styles.inputFloating}
                                            value={reference}
                                            onChange={(e) => setReference(e.target.value)}
                                        />
                                        <label htmlFor="floatingReference" className={styles.label}>Nro. SIGEDE</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>    
                            <Row style={{ marginBottom: ".5rem" }}>
                            <Form.Group as={Col} onClick={()=>setShowCrimeModal(true)}>
                                <Form.Floating>
                                    <Form.Select
                                    id="floatingCrime"
                                    className={styles.inputFloating}
                                    
                                    >
                                    </Form.Select>
                                    <label htmlFor="floatingCrime" className={styles.label}>
                                    Hechos Punibles
                                    </label>
                                </Form.Floating>
                                </Form.Group>
                            <CrimesModal showModal={showCrimeModal} handleClose={handleCloseCrimeModal} updateSelectedCrimes={setSelectedCrimes}/>
                           
                            </Row>                        
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Select id="floatingStatus" className={styles.inputFloating} value={status} onChange={(e) => { setStatus(e.target.value); }}> 
                                            <option value={"TODOS"} selected>TODOS</option>
                                            {
                                                Object.keys(ComplaintStatus).map( key => (
                                                    <option value={key}>{ComplaintStatus[key].name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <label htmlFor="floatingStatus" className={styles.label}>Estado</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Select id="floatingComplaintType" className={styles.inputFloating} value={status} onChange={(e) => { setComplaintType(e.target.value); }}> 
                                            <option value={"TODOS"} selected>TODOS</option>
                                            {
                                                Object.keys(ComplaintType).map( key => (
                                                    <option value={key}>{ComplaintType[key].name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <label htmlFor="floatingComplaintType" className={styles.label}>Tipo de denuncia</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingGteDate"
                                            className={styles.input} 
                                            type="date" 
                                            value={gteDate} 
                                            onChange={(e) => { setGteDate(e.target.value); }}
                                        />
                                        <label htmlFor="floatingGteDate" className={styles.label}>Fecha inicio</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingLteDate"
                                            className={styles.input} 
                                            type="date" 
                                            value={lteDate} 
                                            onChange={(e) => { setLteDate(e.target.value); }}
                                        />                                        
                                        <label htmlFor="floatingLteDate" className={styles.label}>Fecha fin</label>
                                    </Form.Floating>                                        
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingGteStatementDate"
                                            className={styles.input} 
                                            type="date" 
                                            value={gteDate} 
                                            onChange={(e) => { setGteStatementDate(e.target.value); }}
                                        />
                                        <label htmlFor="floatingGteStatementDate" className={styles.label}>Hecho punible desde</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingLteStatementDate"
                                            className={styles.input} 
                                            type="date" 
                                            value={lteDate} 
                                            onChange={(e) => { setLteStatementDate(e.target.value); }}
                                        />                                        
                                        <label htmlFor="floatingLteStatementDate" className={styles.label}>Hecho punible hasta</label>
                                    </Form.Floating>                                        
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col style={{ textAlign: "right", marginTop: "auto", display: "flex" }}>
                                    <DropdownButton bsPrefix={styles.secondary} title="Ordenar" style={{ marginLeft: "auto" }}>
                                        <Dropdown.Item onClick={(e) => sortingBy("complaint_id", "Identificador")} className={styles.item}>ID de la denuncia</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => sortingBy("created_at", "Fecha de recepción")} className={styles.item}>Fecha de recepción</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => sortingBy("status", "Estado")} className={styles.item}>Estado</Dropdown.Item>
                                    </DropdownButton>&nbsp;&nbsp;
                                    <Button onClick={reset}  bsPrefix={styles.primary}>Limpiar <AiOutlineClear /></Button>&nbsp;&nbsp;
                                    <Button onClick={() => findComplaints()}  bsPrefix={styles.primary}>Buscar <AiOutlineSearch /></Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col md={9}>
                    <div className={styles.container}>
                        <h2>Denuncias</h2>
                        <Row>
                            <Col>
                                <Button onClick={(e) => {e.preventDefault(); navigate("/denuncias/registrar"); }} bsPrefix={styles.newPrimary} style={{ marginLeft: "auto" }}>&nbsp;&nbsp;Nueva denuncia &nbsp;&nbsp;<FaFileCirclePlus />&nbsp;&nbsp;</Button>
                            </Col>                                
                        </Row>
                        <Row style={{ flexDirection: "row-reverse" }}>
                            <Col md="auto" style={{ textAlign: "right" }}>
                                <InputGroup style={{ flexWrap: "wrap", alignItems: "stretch", justifyContent: "flex-end" }}>
                                    <RiTableFill style={{ marginTop: "auto" }} className={ view == "T" ? styles.iconActive : styles.iconInactive } onClick={(e) => { e.preventDefault(); setView("T"); setPageSize(20); setPage(1); }} />
                                    &nbsp;
                                    <RiArticleFill style={{ marginTop: "auto" }} className={ view == "C" ? styles.iconActive : styles.iconInactive } onClick={(e) => { e.preventDefault(); setView("C"); setPageSize(2); setPage(1); }}/>
                                </InputGroup>
                            </Col>                             
                            <Col md="auto" style={{ textAlign: "right" }}>
                                {
                                    sorting.map((sort) => (
                                        <>
                                            <Badge className={styles.sortItem}>
                                                {sort.label}
                                                &nbsp;
                                                {
                                                    sort.direction === "ASC"
                                                    ? <RiSortAsc style={{ cursor: "pointer" }} onClick={(e) => { changeDirection(sort) }}/> 
                                                    : <RiSortDesc style={{ cursor: "pointer" }} onClick={(e) => { changeDirection(sort) }}/>
                                                }
                                                &nbsp; 
                                                <RiCloseFill style={{ cursor: "pointer" }} onClick={(e) => {sortingBy(sort.key)}}/>
                                            </Badge>&nbsp;
                                        </>
                                    ))
                                }
                            </Col>
                        </Row>                         
                        <Row style={{ marginTop: ".5rem", marginBottom: "1.5rem"}}>
                            <Col md={6}>
                                <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={(page) => { setPage(page); }} totalPages={totalPages}/>
                            </Col>
                            <Col md={6} style={{ textAlign: "right", marginTop: "auto" }}>
                                <span>{totalElements} {totalElements > 1 ? "denuncias" : "denuncia"}</span>
                            </Col>
                        </Row>
                        {
                            loading ? <span className="mt-3 mb-2">Buscando...</span>
                            : 
                            (!loading && (!complaints || complaints.length == 0)) ? <span className="mt-3 mb-2">No hay resultados</span>
                            :
                            (
                                <>
                                    { 
                                        view == "T" ? 
                                        (
                                            <Table className={styles.table}>
                                                <thead>
                                                    <th>N° denuncia</th>
                                                    <th>N° SIGEDE</th>                                                        
                                                    <th>Fecha de recepción</th>
                                                    <th>Rcibida por</th>
                                                    <th>Unidad asignada</th>
                                                    <th>Estado</th>
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        complaints.map((complaint) => (
                                                            <tr>
                                                                <td>{complaint.id}</td>
                                                                <td>{complaint.causeReference}</td>                                                                    
                                                                <td>{complaint.createdAt}</td>
                                                                <td>{`${complaint.createdBy.firstName} ${complaint.createdBy.lastName}`}</td>
                                                                {
                                                                    complaint.prosecutorDivisions && complaint.prosecutorDivisions.length>0 ? (<td>{`${complaint.prosecutorDivisions.find(c => c.principal)?.prosecutorDivisionName || "-"}`}</td>)
                                                                    : (complaint.prosecutor ? (
                                                                        <>
                                                                            <td>{`${complaint.prosecutor?.firstName} ${complaint.prosecutor?.lastName}`}</td>
                                                                        </>
                                                                    ):<td>-</td>)
                                                                }
                                                                <td>{ComplaintStatus[complaint.status].name}</td>
                                                            </tr> 
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        ) 
                                        :
                                        (
                                            complaints.map(
                                                (complaint) => (
                                                    checkRoles(keycloak) === "ddea"
                                                    ? <EconomicCrimesUnitComplaint callback={() => { findComplaints(); }} data={complaint}/>
                                                    : <Denuncia role={checkRoles(keycloak)} callback={() => { findComplaints(); }} complaint={complaint}/> 
                                                )
                                            )
                                        ) 
                                    }
                                </>
                            )
                        }                                       
                    </div>                    
                </Col>
            </Row>
        </>
    )
}

export default DenunciasConsulta;
  