import React, { useEffect, useState } from "react";
import { Table, Button, Spinner, Form } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useFetch } from "../../../../components/hooks/get";
import styles from "../prosecutorUnit.module.css";

const ProsecutorUnitHistory = ({ setModalHistory }) => {
  const { keycloak } = useKeycloak();
  const [divisionHistoryData, setDivisionHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAssistant, setIsAssistant] = useState(false);
  const [filterUnit, setFilterUnit] = useState(""); // Estado para filtrar por unidad fiscal

  const { todo: divisionData, isFetchingDivisionData } = useFetch(
    `${process.env.REACT_APP_API_URL}/divisions/history`,
    keycloak.token
  );

  useEffect(() => {
    if (divisionData) {
      setDivisionHistoryData(divisionData);
      setIsLoading(false);
    }
  }, [divisionData]);

  const renderRows = (data) => {
    return data
      .filter((item) =>
        item.name.toLowerCase().includes(filterUnit.toLowerCase())
      ) // Filtro por unidad fiscal
      .flatMap((item) => {
        const histories = isAssistant
          ? item.assistant_history
          : item.prosecutors_history;
        return histories.length > 0
          ? histories.map((history, index) => (
              <tr key={`${item.division_id}-${index}`}>
                <td>{item.name}</td>
                <td>
                  {isAssistant
                    ? `${history.prosecutorAssistant.firstName} ${history.prosecutorAssistant.lastName}`
                    : `${history.prosecutor.firstName} ${history.prosecutor.lastName}`}
                </td>
                <td>
                  {`${history.from.split(" ")[0]} a ${
                    history.to ? history.to.split(" ")[0] : "Actual"
                  }`}
                </td>
              </tr>
            ))
          : [
              <tr key={`${item.division_id}-empty`}>
                <td>{item.name}</td>
                <td>{isAssistant ? "Sin asistente" : "Sin fiscal"}</td>
                <td>Sin historial</td>
              </tr>,
            ];
      });
  };

  return (
    <div className={styles.historyContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Button
          bsPrefix={styles.newPrimary}
          onClick={() => setModalHistory(false)}
        >
          Volver
        </Button>
        <Button
          bsPrefix={styles.newPrimary}
          onClick={() => setIsAssistant(!isAssistant)}
        >
          {isAssistant ? "Ver fiscales" : "Ver asistentes"}
        </Button>
      </div>

      <Form.Group controlId="filterUnit" style={{ marginBottom: "1rem" }}>
        <Form.Label className={styles.label}>Filtrar por unidad fiscal</Form.Label>
        <Form.Control
          type="text"
          placeholder="Ingrese el nombre de la unidad fiscal"
          value={filterUnit}
          className={styles.input}
          onChange={(e) => setFilterUnit(e.target.value)}
        />
      </Form.Group>

      {isLoading || isFetchingDivisionData ? (
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <Table className={styles.table}>
          <thead>
            <th>Unidad Fiscal</th>
            <th>Fiscal Asignado</th>
            <th>Fecha</th>
          </thead>
          <tbody>{renderRows(divisionHistoryData)}</tbody>
        </Table>
      )}
    </div>
  );
};

export default ProsecutorUnitHistory;
