import { useState, useEffect } from "react";
import axios from "axios";

export const useFetch = (path, token) => {
    //Guardando los datos del fetch
    const [todo, setTodo] = useState([]);
    //Si esta cargando..
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        if (token)
            axios
                .get(path, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((res) => {
                    setTodo(res.data);
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    setIsFetching(false);
                });
    }, [token, path]);

    return { todo, isFetching }
}
