import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Row, Col, Badge } from "react-bootstrap";
import styles from "../DenunciaForm.module.scss";
import keycloak, { checkRoles } from "../../../Keycloak";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
// NEW: We add these two icons, alongside FiTrash (already imported).
import { FiTrash, FiSquare, FiCheckSquare } from "react-icons/fi";

const DatosGenerales = ({ next, data }) => {

    const [ type, setType ] = useState("ESCRITA");
    const [ resolutionNumber, setResolutionNumber ] = useState(undefined);
    const [ resolutionDescription, setResolutionDescription ] = useState(undefined);
    const [ causeReference, setCauseReference ] = useState(undefined);
    const [ reserved, setReserved ] = useState(false);
    const [ validated, setValidated ] = useState(false);
    const [ attachment, setAttachment ] = useState(undefined);
    const [ divisions, setDivisions ] = useState([]);
    const [ loading, setLoading ] = useState(false);    
    const [ division, setDivision ] = useState(undefined);
    const [ file, setFile ] = useState(undefined);

    const [selectedDivisions, setSelectedDivisions] = useState([]);

    const [principalId, setPrincipalId] = useState(null);
    const hiddenRef = useRef(null);

    const handlePrincipalToggle = (id) => {
        if (id === principalId) {
            setPrincipalId(null);
        } else {
            setPrincipalId(id);
        }
    };

    const types = [
        {
            label: "Escrita",
            value: "ESCRITA"
        },
        {
            label: "Verbal",
            value: "VERBAL"
        },
        {
            label: "Por resolución",
            value: "RESOLUCION"
        }
    ];

    const isExternalInstitution = () => {
        let role = checkRoles(keycloak);
        return role === "external-institution";
    };

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    };

    const reservedOptions = [
        { value: true, name: "Sí" },
        { value: false, name: "No" }
    ];

    const handleNext = () => {
        let divisionToSend = [];
        if (selectedDivisions) {
            for(let i=0; i<selectedDivisions.length>0; i++){
                const divi = selectedDivisions[i]
                divisionToSend[i] = {};
                divisionToSend[i].prosecutorDivisionId = divi.id;
                divisionToSend[i].prosecutorDivisionName = divi.name;
                divisionToSend[i].prosecutorFirstName = divi.prosecutor?.firstName;
                divisionToSend[i].prosecutorLastName = divi.prosecutor?.lastName;
                divisionToSend[i].principal = principalId === divi.id;
            }
        }
        next({
            type,
            reserved,
            resolution: type === "RESOLUCION" ? { number: resolutionNumber, description: resolutionDescription, file } : undefined,
            causeReference,
            prosecutorDivisions: divisionToSend
        });
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            setValidated(true);
            handleNext();
        }
    };

    const findDivisions = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/divisions`, prepareHeaders())
            .then((res) => {
                setDivisions(res.status === 200 ? res.data : []);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setDivisions([]);
                setLoading(false);
            });
    };

    const handleChangeFile = async (e) => {
        e.preventDefault();
        setAttachment(e.target.files[0]);
        let fileBody = await buildFile(e.target.files[0]);
        setFile(fileBody);
    };

    const buildFile = async (file) => {
        return await new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve({
                    fileName: file.name,
                    payload: reader.result
                });
            };
            reader.readAsDataURL(file);
        });
    };

    const handleSelectDivision = (divJSON) => {
        
        if (!divJSON) return; 
        
        const newDivision = JSON.parse(divJSON); 
        if (selectedDivisions.some((selDiv) => selDiv.id === newDivision.id)) {
            return;
        }

        setSelectedDivisions((prev) => [...prev, newDivision]);


        setDivision(divJSON);
    };

    const handleRemoveDivision = (divToRemove) => {
        setSelectedDivisions((prev) => prev.filter((d) => d.id !== divToRemove.id));
    };

    useEffect(() => findDivisions(), []);

    useEffect(() => {
        if (data) {
            setType(data.type);
            setReserved(data.reserved);
        }
    }, [data]);

    useEffect(() => {
        if(selectedDivisions.length==0){
           setDivision("") 
        }
      }, [selectedDivisions]);

    return (
        <Form validated={validated} onSubmit={handleSubmit}>
            {
                !isExternalInstitution() ? (
                    <Row className="mb-3 mt-2">
                        <Form.Group as={Col} controlId="typeValidation">
                            <Form.Label className={styles.label}>Tipo denuncia</Form.Label>
                            {
                                types.map((current) => (
                                    <Form.Check type="radio" key={current.value}>
                                        <Form.Check.Input
                                            type="radio"
                                            value={current.value}
                                            onChange={(e) => { setType(e.target.value); }}
                                            checked={type === current.value}
                                            className={styles.checkbox}
                                        />
                                        <Form.Check.Label className={styles.checkboxLabel}>{current.label}</Form.Check.Label>
                                    </Form.Check>
                                ))
                            }
                            <Form.Control.Feedback>{`Denuncia ${type} ✓`}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Debes seleccionar el tipo de la denuncia</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                )
                :
                <></>
            }

            {
                type === "RESOLUCION" ? (
                    <>
                        <Row className="mb-2">
                            <Form.Label className={styles.label}>Datos de la resolución</Form.Label>
                            <Form.Group as={Col} md={3}>
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingResolutionNumber"
                                        type="text"
                                        placeholder="Ingrese el número de resolución"
                                        className={styles.input}
                                        required
                                        value={resolutionNumber}
                                        onChange={(e) => { setResolutionNumber(e.target.value); }}
                                    />
                                    <label htmlFor="floatingResolutionNumber" className={styles.label}>Número</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} md={9}>
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingResolutionDescription"
                                        type="text"
                                        placeholder="Ingrese la descripción"
                                        className={styles.input}
                                        required
                                        value={resolutionDescription}
                                        onChange={(e) => { setResolutionDescription(e.target.value); }}
                                    />
                                    <label htmlFor="floatingResolutionDescription" className={styles.label}>Descripción</label>
                                </Form.Floating>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col} md={4}>
                                <Form.Floating>
                                    <Form.Control
                                        as="select"
                                        id="floatingProsecutor"
                                        placeholder="Fiscal asignado"
                                        className={styles.input}
                                        value={division}
                                        required
                                        onChange={(e) => handleSelectDivision(e.target.value)}
                                    >
                                        <option value="">Seleccione</option>
                                        {
                                            divisions.map((d) => (
                                                <option
                                                    key={d.id}
                                                    value={JSON.stringify(d)}
                                                >
                                                    {`${d.name} - ${d.prosecutor?.firstName} ${d.prosecutor?.lastName}`}
                                                </option>
                                            ))
                                        }
                                    </Form.Control>
                                    <label htmlFor="floatingProsecutor" className={styles.label}>Unidad Fiscal asignada</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Floating>
                                    <Form.Control
                                        type="file"
                                        placeholder="Resolución adjunta"
                                        className={styles.input}
                                        onChange={handleChangeFile}
                                        required
                                    />
                                </Form.Floating>
                            </Form.Group>
                        </Row>

                        <Row className={styles.table}>
                            <Col md={8}>
                                {selectedDivisions.length > 0 && (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Unidad</th>
                                                <th>Fiscal</th>
                                                <th>Unidad fiscal principal</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedDivisions.map((d) => (
                                                <tr key={d.id}>
                                                    <td>{d.name}</td>
                                                    <td>
                                                        {d.prosecutor?.firstName} {d.prosecutor?.lastName}
                                                    </td>
                                                    <td>
                                                    
                                                        <span
                                                            style={{ marginRight: "1rem", cursor: "pointer" }}
                                                            onClick={() => handlePrincipalToggle(d.id)}
                                                        >
                                                            {principalId === d.id ? (
                                                                <FiCheckSquare style={{ marginRight: "0.2rem" }} />
                                                            ) : (
                                                                <FiSquare style={{ marginRight: "0.2rem" }} />
                                                            )}
                                                            
                                                        </span>
                                                        
                                                    </td>
                                                    <td><FiTrash style={{ cursor: "pointer" }} onClick={() => handleRemoveDivision(d)} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </Col>
                        </Row>
                    </>
                )
                :
                <></>
            }

            <Row className="mb-4">
                <Form.Group as={Col} controlId="reservedValidation">
                    <Form.Label className={styles.label}>¿La denuncia es reservada?</Form.Label>
                    {
                        reservedOptions.map(( current ) => (
                            <Form.Check type="radio" key={current.name}>
                                <Form.Check.Input
                                    id={current.name}
                                    name="group1"
                                    required
                                    type="radio"
                                    onChange={(e) => { setReserved(e.target.value); }}
                                    value={current.value}
                                    defaultChecked={reserved == current.value}
                                    className={styles.checkbox}
                                />
                                <Form.Check.Label htmlFor={current.name} className={styles.checkboxLabel}>{current.name}</Form.Check.Label>
                            </Form.Check>
                        ))
                    }
                    <Form.Control.Feedback>{`Denuncia ${reserved ? "reservada" : "no reservada"} ✓`}</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Debes marcar si es una denuncia reservada o no</Form.Control.Feedback>
                </Form.Group>
            </Row>

            {
                !isExternalInstitution() ? (
                    <Row className="mb-4">
                        <Form.Label className={styles.label}>Nro. SIGEDE</Form.Label>
                        <Form.Group as={Col} md={7}>
                            <Form.Floating>
                                <Form.Control
                                    id="floatingCauseReference"
                                    type="text"
                                    className={styles.input}
                                    value={causeReference}
                                    onChange={(e) => { setCauseReference(e.target.value); }}
                                />
                                <label htmlFor="floatingCauseReference" className={styles.label}>Número de referencia</label>
                            </Form.Floating>
                        </Form.Group>
                    </Row>
                )
                :
                (<></>)
            }

            <Row className="mt-4" style={{ borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                    <Button type="submit" bsPrefix={styles.next}>SIGUIENTE</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default DatosGenerales;
