import React, { useEffect } from "react";
import { Button,Form, Modal, Table } from "react-bootstrap";

import styles from "../prosecutorUnit.module.css";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FiTrash } from "react-icons/fi";

const ProsecutorUnitEdit = ({ show, divisionId, setModalEdit }) => {
  const { keycloak } = useKeycloak();
  const [isCreate, setIsCreate] = useState(false);
  const [divisionData, setDivisionData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistantId, setSelectedAssistantId] = useState(null);


  
  useEffect(() => {
    findAssistants();
  }, []);
  useEffect(() => {
    fetchDivisionData();
  }, [divisionId]);

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const fetchDivisionData = async () => {
    if (!divisionId) return;
    setIsLoading(true);

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/divisions/${divisionId}`,
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      );
      console.log(data);

      setDivisionData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error al obtener la división:", error);
      setIsLoading(false);
    }
  };
  const findAssistants = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/prosecutors/assistants`,
      {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    setAssistants(data);
  };

  const handleAddAssistant = async (assistantId) => {
    if (!assistantId)
      return toast("Seleccione un asistente", { position: "bottom-right" });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/divisions/${divisionId}/assistant`,
        { prosecutor_assistant_id: assistantId },
        prepareHeaders()
      );
      fetchDivisionData();
      toast("El asistente se agrego correctamente", {
        position: "bottom-right",
      });
      setIsCreate(false);
    } catch (error) {
      console.error("Error al agregar el asistente:", error);
      toast("Ocurrio un error al agregar el asistente", {
        position: "bottom-right",
      });
    }
  };

  const handleDeleteAssistant = async (assistantId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/divisions/${divisionId}/assistant/${assistantId}`,
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      );
      fetchDivisionData();
      toast("El asistente se elimino correctamente", {
        position: "bottom-right",
      });
    } catch (error) {
      console.error("Error al eliminar el asistente:", error);
      toast("Ocurrio un error al eliminar el asistente", {
        position: "bottom-right",
      });
    }
  };

  return (
    <>
      <Toaster />
      <Modal show={show} onHide={setModalEdit}>
        <Modal.Header>
          <Modal.Title>
            <h5>Asistentes</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setSelectedAssistantId(e.target.value)}
          >
            <option>Seleccione un asistente</option>
            {assistants.map((assistant) => {
              return (
                <option key={assistant.id} value={assistant.id}>
                  {assistant.firstName + " " + assistant.lastName}
                </option>
              );
            })}
          </Form.Select>
          <Table className={styles.table}>
              <thead>
                <th>Nombre</th>
                <th>Acciones</th>
              </thead>
              <tbody>
                {divisionData?.prosecutorAssistants?.map((assistant) => (
                  <tr key={assistant.id}>
                    <td>{assistant.firstName + " " + assistant.lastName}</td>
                    <td>
                      <FiTrash
                        title="Eliminar"
                        onClick={() => {
                          handleDeleteAssistant(assistant.id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsPrefix={styles.newPrimary}
            style={{ marginLeft: "2px" }}
            onClick={() => handleAddAssistant(selectedAssistantId)}
          >
            Agregar
          </Button>
          <Button
            bsPrefix={styles.newPrimary}
            style={{ marginLeft: "2px" }}
            onClick={setModalEdit}
          >
            Volver
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProsecutorUnitEdit;
