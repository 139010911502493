import React, { useState } from "react";
import styles from "./Admin.module.css";
import Organizations from "./Organizations";
import ExtintionRisk from "./ExtintionRisk";
import ComplexityRules from "./ComplexityRules";
import CompetenceRules from "./CompetenceRules";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Shifts from "./Shifts";
import ProsecutorUnit from "./ProsecutorUnit";

const Admin = () => {
  const [menu, setMenu] = useState("shifts");

  return (
    <>
      <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col md={2}>
          <div className={styles.sideBar}>
            <Link
              className={`${styles.button} ${
                menu === "prosecutorUnit" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("prosecutorUnit");
              }}
            >
              <span>Unidad Fiscal</span>
            </Link>
            <Link
              className={`${styles.button} ${
                menu === "shifts" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("shifts");
              }}
            >
              <span>Turnos</span>
            </Link>
            <Link
              className={`${styles.button} ${
                menu === "competence_rules" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("competence_rules");
              }}
            >
              <span>Reglas de competencia</span>
            </Link>
            <Link
              className={`${styles.button} ${
                menu === "complexity_rules" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("complexity_rules");
              }}
            >
              <span>Reglas de complejidad</span>
            </Link>
            <Link
              className={`${styles.button} ${
                menu === "extintion_risk" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("extintion_risk");
              }}
            >
              <span>Riesgo de extinción</span>
            </Link>
            <Link
              className={`${styles.button} ${
                menu === "organizations" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("organizations");
              }}
            >
              <span>Organizaciones</span>
            </Link>
          </div>
        </Col>
        <Col md={10}>
          <div className={styles.container}>
            {menu === "prosecutorUnit" ? <ProsecutorUnit/>:<></>}
            {menu === "shifts" ? <Shifts /> : <></>}
            {menu === "competence_rules" ? <CompetenceRules /> : <></>}
            {menu === "complexity_rules" ? <ComplexityRules /> : <></>}
            {menu === "extintion_risk" ? <ExtintionRisk /> : <></>}
            {menu === "organizations" ? <Organizations /> : <></>}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Admin;
