import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import styles from "../prosecutorUnit.module.css";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";

const ProsecutorUnitEditProsecutor = ({ show, divisionId, setModalEdit }) => {
  const { keycloak } = useKeycloak();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProsecutorId, setSelectedProsecutorId] = useState(null);
  const [divisionData, setDivisionData] = useState([]);
  const [prosecutorsList, setProsecutorsList] = useState([]);
  const findProsecutors = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/prosecutors`, prepareHeaders())
      .then((res) => {
        setProsecutorsList(res.status == 200 ? res.data : []);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setProsecutorsList([]);
        setIsLoading(false);
      });
  };
  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };
  useEffect(() => {
    findProsecutors();
  }, []);

  useEffect(() => {
    fetchDivisionData();
  }, [divisionId]);

  const fetchDivisionData = async () => {
    if (!divisionId) return;
    setIsLoading(true);

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/divisions/${divisionId}`,
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      );
      setDivisionData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error al obtener la división:", error);
      setIsLoading(false);
    }
  };

  const handleUpdateProsecutor = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/divisions/${divisionId}/`,

        { prosecutor_id: parseInt(selectedProsecutorId) },
        prepareHeaders()
      );
      fetchDivisionData();
      toast("Se actualizo el fiscal correctamente", {
        position: "bottom-right",
      });
      setModalEdit();
    } catch (error) {
      console.error("Error al actualizar fiscal:", error);
      toast("Ocurrio un error al actualizar el fiscal", {
        position: "bottom-right",
      });
    }
  };

  return (
    <>
      <Toaster />
      <Modal show={show} onHide={setModalEdit}>
        <Modal.Header>
          <Modal.Title>
            <h5>Agregar Fiscal</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setSelectedProsecutorId(e.target.value)}
          >
            <option>Seleccione un fiscal</option>
            {prosecutorsList.map((prosecutor) => {
              return (
                <option key={prosecutor.id} value={prosecutor?.id ?? null}>
                  {`${prosecutor?.firstName ?? ""} ${prosecutor?.lastName ?? ""}`}
                </option>
              );
            })}
          </Form.Select>
          <div
            style={{ marginTop: "2vh", display: "flex", justifyContent: "end" }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsPrefix={styles.newPrimary}
            style={{ marginLeft: "2px" }}
            onClick={() => handleUpdateProsecutor(selectedProsecutorId)}
          >
            Asignar
          </Button>
          <Button
            bsPrefix={styles.newPrimary}
            style={{ marginLeft: "2px" }}
            onClick={setModalEdit}
          >
            Volver
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProsecutorUnitEditProsecutor;
