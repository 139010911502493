import React, { useState, useEffect } from "react";
import styles from "./ShiftRegister.module.css";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";

const ShiftRegister = ({ show, callback, handleClose }) => {

    const [ loading, setLoading ] = useState(false);
    const [ prosecutor, setProsecutor ] = useState(undefined);
    const [ prosecutors, setProsecutors ] = useState([]);
    const [ startDate, setStartDate ] = useState(undefined);
    const [ endDate, setEndDate ] = useState(undefined);
    const [ division, setDivision ] = useState(undefined);
    const [ divisions, setDivisions ] = useState([]);

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }      

    const body = () => {
        return {
            prosecutor_id: prosecutor.id,
            division_id: division,
            start_date: startDate,
            end_date: endDate
        }
    }

    const handleRegister = (e) => {
        e.preventDefault();
        setLoading(true);        
        axios.post(`${process.env.REACT_APP_API_URL}/shifts`, body(), prepareHeaders()).
        //axios.post("http://localhost:8082/udea.deputy-prosecutor/api/shifts", body(), prepareHeaders()).
        then(res => {
            console.log(res);
            setLoading(false);
            callback();
        }).
        catch(err => {
            console.log(err);
            setLoading(false);            
        })
    }

    const findProsecutors = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/prosecutors`, prepareHeaders()).
        then(res => { setProsecutors(res.status != 200 ? [] : res.data); }).
        catch(err => { console.log(err); });
    }

    const findDivisions = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/divisions`, prepareHeaders()).
        then(res => { setDivisions(res.status != 200 ? [] : res.data); }).
        catch(err => { console.log(err); });
    }

    useEffect(() => {findProsecutors();findDivisions();}, []);

    return (
        <>
            <Toaster />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title><h5>Datos del turno</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>     
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className={styles.label}>Unidad Fiscal</Form.Label>
                                <Form.Control as="select" className={styles.input} value={division} onChange={(e) => { e.preventDefault(); setDivision(e.target.value); setProsecutor(divisions.find(division=>division.id==e.target.value)?.prosecutor)}}>
                                    <option value={0}>Seleccione</option>
                                    {
                                        divisions.map((division) => (
                                            <option value={division.id}>{division.name}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Row>        
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className={styles.label}>Fiscal de la unidad</Form.Label>
                                <Form.Control disabled as="select" className={styles.input} value={division} >
                                    <option value={0}>{prosecutor !=undefined ? prosecutor?.firstName +" "+prosecutor?.lastName:"Ninguno"}</option>

                                </Form.Control>
                            </Form.Group>
                        </Row>               
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className={styles.label}>Fecha inicio</Form.Label>
                                <Form.Control type="date" className={styles.input} value={startDate} onChange={(e) => { setStartDate(e.target.value); }} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={styles.label}>Fecha fin</Form.Label>
                                <Form.Control type="date" className={styles.input} value={endDate} onChange={(e) => { setEndDate(e.target.value); }} />
                            </Form.Group>
                        </Row>                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary" onClick={handleRegister} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Guardando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }
                    </Button>                    
                </Modal.Footer>
            </Modal>            
        </>
    );

}

export default ShiftRegister;