import React, { useState } from 'react'
import Stepper from "../../components/Stepper";
import Step from "../../components/Stepper/Step";
import DatosGenerales from "./DatosGenerales";
import Denunciados from "./Denunciados";
import Denunciantes from "./Denunciantes";
import Perjudicados from "./Perjudicados";
import Hecho from "./Hecho";
import Testigos from "./Evidencias/Testigos";
import InformesPericiales from "./Evidencias/InformesPericiales";
import DocumentosAdjuntos from "./Evidencias/DocumentosAdjuntos";
import Preview from './Preview/Preview';
import keycloak, { checkRoles } from "../../Keycloak";

const DenunciaForm = ( props ) => {

    const [ datosGenerales, setDatosGenerales ] = useState(undefined);
    const [ denunciantes, setDenunciantes ] = useState([]);
    const [ perjudicados, setPerjudicados ] = useState([]);
    const [ denunciados, setDenunciados ] = useState([]);
    const [ hecho, setHecho ] = useState(undefined);
    const [ testigos, setTestigos ] = useState([]);
    const [ informesPericiales, setInformesPericiales ] = useState(undefined);
    const [ documentosAdjuntos, setDocumentosAdjuntos ] = useState([]);

    const [ current, setCurrent ] = useState(1);
    const [ currentEvidencia, setCurrentEvidencia ] = useState(1);

    const [ data, setData ] = useState(undefined);
    const [ preview, setPreview ] = useState(false);

    const isExternalInstitution = () => {
        let role = checkRoles(keycloak);
        return role == "external-institution";
    }    

    const createSteps = () => {
        if(isExternalInstitution()){
            return [
                {  
                    label: "Datos generales",
                    code: "1",
                    id: 1
                },
                {  
                    label: "Datos de los perjudicados",
                    code: "2",
                    id: 2
                },
                {  
                    label: "Datos de los denunciados",
                    code: "3",            
                    id: 3
                },
                {  
                    label: "Datos del hecho",
                    code: "4",
                    id: 4
                },
                {
                    label: "Evidencias",
                    code: "5",
                    id: 5
                }
            ];
        }
        else {
            return [
                {  
                    label: "Datos generales",
                    code: "1",
                    id: 1
                },
                {  
                    label: "Datos de los denunciantes",
                    code: "2",            
                    id: 2
                },
                {  
                    label: "Datos de los perjudicados",
                    code: "3",            
                    id: 3
                },
                {  
                    label: "Datos de los denunciados",
                    code: "4",            
                    id: 4
                },
                {  
                    label: "Datos del hecho",
                    code: "5",            
                    id: 5
                },
                {
                    label: "Evidencias",
                    code: "6",            
                    id: 6
                }
            ];            
        }
    }

    const steps = createSteps();    

    const evidenciasSteps = [
        {
            label: "Testigos",
            code: "6.1",            
            id: 1
        },
        {
            label: "Informes perciales",
            code: "6.2",            
            id: 2
        },
        {
            label: "Documentos adjuntos",
            code: "6.3",
            id: 3
        } 
    ];
    
    const handleNextDatosGenerales = ( data ) => {
        setDatosGenerales(data);
        setCurrent(current + 1);
    };

    const handleNextDenunciantes = ( data ) => {
        setDenunciantes(data);
        setCurrent(current + 1);
    };

    const handlePrevDenunciantes = ( data ) => {
        setDenunciantes(data);
        setCurrent(current - 1);
    };

    const handleNextPerjudicados = ( data ) => {
        setPerjudicados(data);
        setCurrent(current + 1);
    };

    const handlePrevPerjudicados = ( data ) => {
        setPerjudicados(data);
        setCurrent(current - 1);
    }

    const handleNextDenunciados = ( data ) => {
        setDenunciados(data);
        setCurrent(current + 1);
    }

    const handlePrevDenunciados = ( data ) => {
        setDenunciados(data);
        setCurrent(current - 1);
    }    

    const handleNextHechos = ( data ) => {
        setHecho(data);
        setCurrent(current + 1);
    }

    const handlePrevHechos = ( data ) => {
        setHecho(data);
        setCurrent(current - 1);
    }

    const handleNextTestigos = ( data ) => {
        setTestigos(data);
        setCurrentEvidencia(currentEvidencia + 1);
    }

    const handlePrevTestigos = ( data ) => {
        setTestigos(data);
        setCurrent(current - 1);
    }

    const handleNextInformesPericiales = ( data ) => {
        setInformesPericiales(data);
        setCurrentEvidencia(currentEvidencia + 1);
    }

    const handlePrevInformesPericiales = ( data ) => {
        setInformesPericiales(data);
        setCurrentEvidencia(currentEvidencia - 1);
    }

    const handleNextDocumentosAdjuntos = ( attachments ) => {
        setDocumentosAdjuntos(attachments);
        setData({
            type: datosGenerales.type,
            prosecutorDivisions: datosGenerales.prosecutorDivisions,
            reserved: datosGenerales.reserved,
            resolution: datosGenerales.resolution,
            prosecutor: datosGenerales.prosecutor,
            causeReference: datosGenerales.causeReference,
            complainants: denunciantes,
            injuredParties: perjudicados,
            defendants: denunciados.map(
                defendant => { 
                    return {
                        ...defendant,
                        publicOfficial: !defendant.publicOfficial ? undefined : {
                            title: defendant.publicOfficialTitle
                        },
                        publiclyExposedPerson: !defendant.pep ? undefined : { 
                            id: defendant.pepTitle.id, 
                            title: defendant.pepTitle.title, 
                            level: defendant.pepTitle.category.level,
                            institution: defendant.pepInstitution
                        }
                    };
                }
            ),
            statementOfFact: { 
                address: hecho.address, 
                possibleDate: hecho.possibleDate,  
                detailedAccountOfThePunishableAct: hecho.fact,
                crimes: hecho.crimes,
                estimatedAmountOfDamage: {
                    value: hecho.amount,
                    currency: "PYG",
                    decimals: 0
                },
                relatedInstitutions: hecho.injuredInstitutions,        
            },
            witnesses: testigos,
            expertsReports: informesPericiales ? informesPericiales.map(
                expertReport => {
                    return {
                        fileName: expertReport.name,
                        id: expertReport.id                        
                    };
                }
            ) : [],
            attachments: attachments.map(
                attachment => { 
                    return { 
                        ...attachment, 
                        file: !attachment.file ? undefined : {
                            fileName: attachment.file.name,
                            id: attachment.file.id
                        }
                    }; 
                } 
            )
        });
        setPreview(true);
    }

    const cancel = (e) => {
        e.preventDefault();
        setPreview(false);
        clear();
    }

    const clear = () => {
        setDatosGenerales(undefined);
        setDenunciantes([]);
        setPerjudicados([]);
        setDenunciados([]);
        setHecho(undefined);
        setTestigos([]);
        setInformesPericiales(undefined);
        setDocumentosAdjuntos([]);
        setCurrent(1);
        setCurrentEvidencia(1);
        setData(undefined);
    }

    const handlePrevDocumentosAdjuntos = ( data ) => {
        setDocumentosAdjuntos(data);
        setCurrentEvidencia(currentEvidencia - 1);
    }

    return (preview && data) ? <Preview complaint={data} setPreview={setPreview} cancel={cancel} /> : (
        <Stepper steps={steps} current={current}>
            <Step step={1}>
                <DatosGenerales next={handleNextDatosGenerales} data={datosGenerales} />
            </Step>
            {
                isExternalInstitution() ? (<></>)
                :
                (
                    <Step step={2}>
                        <Denunciantes next={handleNextDenunciantes} previous={handlePrevDenunciantes} complainants={denunciantes} setComplainants={setDenunciantes} />
                    </Step>
                )
            }            
            <Step step={isExternalInstitution() ? 2 : 3}>
                <Perjudicados next={handleNextPerjudicados} previous={handlePrevPerjudicados} injuredParties={perjudicados} setInjuredParties={setPerjudicados} />
            </Step>
            <Step step={isExternalInstitution() ? 3 : 4}>
                <Denunciados next={handleNextDenunciados} previous={handlePrevDenunciados} defendants={denunciados} setDefendants={setDenunciados} />
            </Step>
            <Step step={isExternalInstitution() ? 4 : 5}>
                <Hecho next={handleNextHechos} previous={handlePrevHechos} data={hecho} />
            </Step>
            <Step step={isExternalInstitution() ? 5 : 6}>
                <Stepper steps={evidenciasSteps} current={currentEvidencia}>
                    <Step step={1}>
                        <Testigos next={handleNextTestigos} previous={handlePrevTestigos} witnesses={testigos} setWitnesses={setTestigos} />
                    </Step>
                    <Step step={2}>
                        <InformesPericiales 
                            next={handleNextInformesPericiales} 
                            previous={handlePrevInformesPericiales} 
                            files={informesPericiales} 
                            setFiles={setInformesPericiales} 
                        />
                    </Step>
                    <Step step={3}>
                        <DocumentosAdjuntos 
                            next={handleNextDocumentosAdjuntos} 
                            previous={handlePrevDocumentosAdjuntos} 
                            attachments={documentosAdjuntos} 
                            setAttachments={setDocumentosAdjuntos} 
                        />
                    </Step>
                </Stepper>
            </Step>
        </Stepper>
    );

}

export default DenunciaForm;