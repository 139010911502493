import React, { useState, useEffect } from "react";
import { Form, Table, Button, Row, Col } from "react-bootstrap";
import styles from "./Shifts.module.css";
import { FaFileCirclePlus } from "react-icons/fa6";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import TimeFilter from "../../dashboard/TimeFilter";
import ShiftRegister from "./ShiftRegister";
import { FiTrash } from "react-icons/fi";
import { AiFillEdit } from "react-icons/ai";

const Shifts = () => {

    const [ shifts, setShifts ] = useState([]);
    const [ loadingShifts, setLoadingShifts ] = useState(false);
    const [ showRegisterShift, setShowRegisterShift ] = useState(false);    
    const { keycloak } = useKeycloak();      

    const [ rangeType, setRangeType ] = useState("year");

    const [ year, setYear ] = useState(new Date().getFullYear());
    const [ month, setMonth ] = useState("01");
    const [ startDate, setStartDate ] = useState(undefined);
    const [ endDate, setEndDate ] = useState(undefined);


    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }     


    const handleCloseModal = (e) => {
        e.preventDefault();
        setShowRegisterShift(false);
    }
    
    const registerCallback = () => {
        setShowRegisterShift(false);
        findShifts();
    }    

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }    

    const format = (date) => {
        date = new Date(date);
        return [
            padTo2Digits(date.getUTCDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear()
        ].
        join('/');
    }

    const lastDayOfMonth = (month, year) => {
        let fecha = new Date(year, month, 1);
        fecha.setDate(fecha.getDate() - 1);
        return fecha.getDate();
    }      

    const buildParams = () => {
        let params = "?";
        switch(rangeType){
            case "year":
                params += "start_date=" + year + "-01-01&";
                params += "end_date=" + year + "-12-31";
                break;
            case "month":
                params += "start_date=" + year + "-" + month + "-01&";
                params += "end_date=" + year + "-" + month + "-" + lastDayOfMonth(month, year);
                break;
            case "custom":
                if(startDate != undefined && endDate != undefined){
                    params += "start_date=" + startDate + "&";
                    params += "end_date=" + endDate;
                }
                break;
            default:
                break;
        }
        return params;
    }    

    const handleDelete = (e, shiftId) => {
        e.preventDefault();
        //axios.get(`${process.env.REACT_APP_API_URL}/shifts`, prepareHeaders()).
        axios.delete(`${process.env.REACT_APP_API_URL}/shifts/${shiftId}`, prepareHeaders()).
        then(res => {
            console.log(res);
            findShifts();
        }).
        catch(err => {
            console.log(err);
            findShifts();            
        });
    }

    const findShifts = () => {
        setLoadingShifts(true);
        //axios.get(`http://localhost:8082/udea.deputy-prosecutor/api/shifts` + buildParams(), prepareHeaders()).
        axios.get(`${process.env.REACT_APP_API_URL}/shifts` + buildParams(), prepareHeaders()).        
        then(res => {
            setShifts(res.data == 204 ? [] : res.data);
            setLoadingShifts(false);
        }).
        catch(err => {
            console.log(err);
            setLoadingShifts(false);            
        })
    };

    useEffect(() => findShifts(), [startDate, endDate, rangeType, month, year]);

    return (
        <>
            <ShiftRegister show={showRegisterShift} handleClose={handleCloseModal} callback={registerCallback} />
            <Form className={styles.group}>
                <Form.Label className={styles.groupTitle}>Turnos</Form.Label>                
                <Row>
                    <Form.Group as={Col}>
                        <Button bsPrefix={styles.newPrimary} onClick={(e) => { e.preventDefault(); setShowRegisterShift(true); }}>&nbsp;&nbsp;Nuevo turno &nbsp;&nbsp;<FaFileCirclePlus />&nbsp;&nbsp;</Button>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <TimeFilter 
                            rangeType={rangeType}
                            setRangeType={setRangeType}
                            year={year}
                            setYear={setYear}
                            month={month}
                            setMonth={setMonth}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />                          
                    </Form.Group>                    
                </Row>
            </Form>
            {
                loadingShifts ? ( <>Buscando...</> )
                : (
                    <>
                        {
                            shifts.length > 0 ? (
                                <Table className={styles.table}>
                                    <thead>
                                        <th>Unidad</th>
                                        <th>Fiscal Asignado</th>
                                        <th>Inicio</th>
                                        <th>Fin</th>
                                        <th>Acciones</th>
                                    </thead>
                                    <tbody>
                                        {
                                            shifts.map((shift) => (
                                                <tr>
                                                    
                                                    <td>{shift.division?.name}</td>
                                                    <td style={{ textAlign: 'left' }}>{shift.division?.prosecutor && (
                                                        <>
                                                        {shift.division?.prosecutor?.firstName} {shift.division?.prosecutor?.lastName}
                                                        </>
                                                    )}</td>
                                                    <td>{format(shift.start_date)}</td>
                                                    <td>{format(shift.end_date)}</td>
                                                    <td>
                                                        <FiTrash title="Eliminar" onClick={(e) => { handleDelete(e, shift.id); }} style={{ cursor: "pointer" }} />
                                                    </td>                                                                                                                                                               
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            ) 
                            : 
                            (
                                <span>No hay registros</span>
                            )
                        }
                    </>
                )

            }
        </>
    );

}

export default Shifts;